import { strings } from "../config/localization";

const InputCell = ({ name, placeholder, type, required, onChange, value }) => {
  return (
    <div className="cell-unit">
      <h4 className="cell-name">
        {name}
        {required ? (
          <span className="span-1">*</span>
        ) : (
          <span className="span-2"> ({strings.optional})</span>
        )}
      </h4>

      <input
        value={value || ""}
        onChange={onChange ? onChange : null}
        className="cell-input"
        placeholder={placeholder}
        type={type || "text"}
      ></input>
    </div>
  );
};

export { InputCell };
