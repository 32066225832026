const PLATFORMS_DATA = [
  {
    image: require(`../assets/plat-1.svg`).default,
    link: "https://www.facebook.com/SmartAchievement",
  },
  {
    image: require(`../assets/plat-2.svg`).default,
    link: "https://www.instagram.com/achievementsmart",
  },
  {
    image: require(`../assets/plat-3.svg`).default,
    link: "https://twitter.com/Smart_Ach",
  },
  {
    image: require(`../assets/plat-4.svg`).default,
    link: "https://www.youtube.com/@SmartAchievement",
  },
  {
    image: require(`../assets/plat-5.svg`).default,
    link: "https://www.linkedin.com/in/smart-achievement",
  },
  {
    image: require(`../assets/plat-6.svg`).default,
    link: "https://www.pinterest.com/achievementsmart/",
  }
]

const PlatformBox = () => {
  return PLATFORMS_DATA.map((platform, i) => (
    <a key={`${i}`} href={`${platform.link}`} target="_blank">
      <img
        className="social-image"
        src={platform.image}
        alt="logo"
      />
    </a>

  ));
};

export default PlatformBox;
