import { strings } from "../../config/localization";

const CHECK_MARK_IMAGE = require("../../assets/Check.svg").default;

export const SERVICES_DATA = [
  {
    title: strings.digitalmedialmanagment,
    description: strings.weManageAllYourSocial,
    innerTitle: strings.whatsIncluded,
    data: [
      {
        image: CHECK_MARK_IMAGE,
        description: strings.socialMediaAccountSetup,
        text: strings.socialMediaAccountSetupText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.socialMediaStrategy,
        text: strings.socialMediaStrategyText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.socialMediaPlan,
        text: strings.socialMediaPlanText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.contentCreation,
        text: strings.contentCreationText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.postings,
        text: strings.postingsText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.socialmedialistening,
        text: strings.socialmedialisteningText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Businesspageoptimization,
        text: strings.BusinesspageoptimizationText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Keyperformancemetrics,
        text: strings.KeyperformancemetricsText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Increasenetworkpopularity,
        text: strings.IncreasenetworkpopularityText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Reputationmanagement,
        text: strings.ReputationmanagementText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Motiongraphics,
        text: strings.MotiongraphicsText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Writingprofessionalcontent,
        text: strings.WritingprofessionalcontentText
      },
    ],
  },

  {
    title: strings.digitalmedialmanagment,
    description: strings.weManageAllYourSocial,
    innerTitle: strings.whatsIncluded,
    data: [
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Graphicvideophotomaker,
        text: strings.GraphicvideophotomakerText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Mediaconsulting,
        text: strings.MediaconsultingText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.InfluencerIdentification,
        text: strings.InfluencerIdentificationText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.industryAndKeywordResearch,
        text: strings.industryAndKeywordResearchText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Creativedevelopment,
        text: strings.creativeDevelopmentText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.campaignManagement,
        text: strings.campaignManagementText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Mediabuying,
        text: strings.mediaBuyingText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Remarketing,
        text: strings.RemarketingText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Designinglogo,
        text: strings.DesigninglogoText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.GEOtargeting,
        text: strings.GEOtargetingText
      },
    ],
  },

  {
    title: strings.Managmentconsultant,
    description: strings.essentialSocialMedia,
    innerTitle: strings.offerings,
    data: [
      {
        image: CHECK_MARK_IMAGE,
        description: strings.connectTheClient,
        text: strings.ConnecttheclientText
      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Publicrelations,
        text: strings.PublicrelationsText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Entrepreneurship,
        text: strings.EntrepreneurshipText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Quality,
        text: strings.QualityText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Humanresources,
        text: strings.HumanresourcesText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Marketresearch,
        text: strings.MarketresearchText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Feasibilitystudy,
        text: strings.FeasibilitystudyText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Businessplan,
        text: strings.BusinessplanText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Processandprocedure,
        text: strings.ProcessandprocedureText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Strategicplan,
        text: strings.StrategicplanText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Capacitybuilding,
        text: strings.CapacitybuildingText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Riskcrisismanagement,
        text: strings.RiskcrisismanagementText

      },
    ],
  },
  {
    title: strings.EventManagement,
    description: strings.optimizeAds,
    innerTitle: strings.whatsIncluded,
    data: [
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Budgeting,
        text: strings.BudgetingText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Communication,
        text: strings.CommunicationText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Suppliers,
        text: strings.SuppliersText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Design,
        text: strings.DesignText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Marketing,
        text: strings.MarketingText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Location,
        text: strings.LocationText

      },
    ],
  },
  {
    title: strings.ManagedIT,
    description: strings.weThinkOfSolutions,
    innerTitle: strings.whatsIncluded,
    data: [
      {
        image: CHECK_MARK_IMAGE,
        description: strings.EndTechnology,
        text: strings.EndTechnologyText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.Strategy,
        text: strings.StrategyText

      },
      {
        image: CHECK_MARK_IMAGE,
        description: strings.ITSupport,
        text: strings.ITSupportText

      },
    ],
  },
];
