// import AboutUsTeam from "../components/about-us-team";
import AboutUsTitle from "../components/aboutustitle";

function AboutUs() {
  return (
    <>
      <AboutUsTitle />
      {/* <AboutUsTeam /> */}
    </>
  );
}

export default AboutUs;
