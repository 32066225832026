import Herosection from "../components/herosection";
import Todo from "../components/todo";
import OurServices from "../components/ourservices";

function Home() {
  return (
    <>
      <Herosection />
      <Todo />
      <OurServices />
    </>
  );
}

export default Home;
