import { strings } from "../config/localization";
import ContactUS from "./contact-us";
import PlatformBox from "./platformBox";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer-image-box">
        <ContactUS />

      </div>
      <div className="footer-p-box">
        <p>© {year} {strings.smartAchievements}. {strings.allRightsReserved}</p>
      </div>
      <div className="footer-platform-box">
        <PlatformBox />
      </div>
    </div>
  );
};

export default Footer;
