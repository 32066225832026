import { strings } from "../config/localization";
import ContactForm from "./contactForm";

const GetInTouch = () => {
  return (
    <div className="get-in-touch" id="contact-us">
      <div className="get-in-touch-title">
        <h1>{strings.letsGetInTouch}</h1>
        <p>
          {strings.sendUsYourRequest}<br></br> {strings.weWillGetBackToYou}

        </p>
      </div>
      <ContactForm />
    </div>
  );
};

export default GetInTouch;
