import { useState } from "react";
import { handleScrolling } from "../utils";
import { strings } from "../config/localization";

const Todo = () => {
  const [active, setActive] = useState(false);
  const handleActive = () => {
    !active ? setActive(true) : setActive(false);
  };
  return (
    <div
      className="todo-section"
      onMouseEnter={handleActive}
      onMouseLeave={handleActive}
    >
      <div className="todo-text">
        <h2>{strings.weTakeDigitalMedia}</h2>
        <p>{strings.soYouCanFocus}</p>
        <button onClick={handleScrolling} className="todo-btn get-in-touch-btn">
          {strings.getInTouch}
        </button>
      </div>
      <div className="todo-img-container">
        <img
          className={`todo-img todo-img-${active ? "active" : null}`}
          src={require('../assets/todo-image.png')}
          alt="todo"
        />
      </div>
      <div className="todo-img-static">
        <img
          className="calender-static"
          src={require('../assets/calender-static.svg')}
          alt="calender-static"
        />
      </div>
    </div>
  );
};

export default Todo;
