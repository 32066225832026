import { strings } from "../config/localization";

const AboutUsTitle = () => {
  return (
    <div className="about-us-title-box">
      <div className="about-us-h1">
        <h1>{strings.whoWeAre}</h1>
        <div className="about-us-text-box">
          <p>
            {strings.weAreSmartAchievements}
          </p>
          <p>
            {strings.weGetToKnowThemAndTheir}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsTitle;
