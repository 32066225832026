const languages = {
  en: {
    services: "Services",
    aboutUs: "About Us",
    getInTouch: "Get in touch with us",
    we: "We",
    create: "create",
    connect: "connect",
    converse: "converse",
    toHelpBrand: "To help brands make a strong DIGITAL MEDIA presence.",
    weBoostBrand:
      "We boost brand visibility and audience connection with creative campaigns, strategy, and innovation.",
    supportedPlatforms: "Supported platforms",
    weTakeDigitalMedia: "We take digital media off your daily “TO-DO” list",
    soYouCanFocus:
      "So you can focus on doing what you do best – grow your business.",
    ourServices: "Our Services",
    boostYourBrand: "Boost your brand online with our all-in-one",
    digitalMediaSolutions: "digital media solutions",
    digitalmedialmanagment: "Digital Media Management",
    weManageAllYourSocial: "We manage all your social media profiles",
    readMore: "Read more",
    socialMediaMarketing: "Social Media Marketing",
    ManagementConsultant: "Management Consultant",
    essentialSocialMedia: "We specialize in management consultation that contributes to raising the quality of work",
    socialMediaAdsManagement: "Social Media Ads Management",
    optimizeAds: "We organize and manage an event where you can find professional event organization along with confident, creative & qualified team.",
    letsGetInTouch: "Let’s Get in Touch!",
    sendUsYourRequest: "Send us your request and our team",
    weWillGetBackToYou: "will get back to you in 24 hours!",
    fullName: "Full Name",
    email: "Email",
    companyName: "Company name",
    mobile: "Mobile",
    yourMessage: "Your message",
    optional: "Optional",
    emailPlaceholder: "example@email.com",
    companyPlaceHolder: "Company",
    mobilePlaceHolder: "96279XXXXXXX",
    letsHaveADiscussion: "Let's have a discussion!",
    send: "Send",
    theBestWayForUs:
      "The best way for us to tell you our story is through the projects that we deliver for our clients",
    wePrioritizeDynamic:
      "We prioritize dynamic and professional strategies for your brand's social media",
    socialMediaAccountSetup: "Social Media Account Setup",
    socialMediaStrategy: "Social Media Strategy",
    socialMediaPlan: "Social Media Plan",
    socialMediaPostDesign: "Social Media post Design",
    contentCreation: "Content Creation",
    postings: "Postings / Ads",
    socialmedialistening: "Social Media listening",
    Businesspageoptimization: "Business page optimization",
    Keyperformancemetrics: "Key performance metrics",
    Increasenetworkpopularity: "Increase network popularity",
    Reputationmanagement: "Reputation management",
    Facebookappdevelopment: "Facebook app development",
    Motiongraphics: "Motion graphics",
    Writingprofessionalcontent: "Writing professional content",
    Graphicvideophotomaker: "Video graphic/photo maker (Innovation)",
    Managmentconsultant: "Managment consultant",
    InfluencerIdentification: "Influencer Identification + Outreach",
    industryAndKeywordResearch: "Industry and keyword research",
    Creativedevelopment: "Creative development",
    campaignManagement: "Campaign management & strategies branding",
    Mediabuying: "Media buying, improving ROI.",
    Remarketing: "Re-marketing pixel deployment, to increase conversion",
    Designinglogo: "Designing logo & brand identity",
    GEOtargeting: "GEO targeting",
    targetedSocialMedia:
      "Targeted social media ads to reach your correct audience & increase brand visibility",
    facebook: "Facebook",
    instagram: "Instagram",
    youtube: "Youtube",
    linkedIn: "LinkedIn",
    twitter: "X (Twitter)",
    pinterest: "Pinterest",
    weBlendSocialMedia:
      "We blend Social Media Marketing with essential Consultancy for successful strategies.",
    Publicrelations: "Public relations",
    Entrepreneurship: "Entrepreneurship",
    Quality: "Quality",
    Humanresources: "Human resources",
    Marketresearch: "Market research / plan",
    Feasibilitystudy: "Feasibility study",
    Businessplan: "Business plan",
    Processandprocedure: "Process and procedure",
    Strategicplan: "Strategic plan",
    Capacitybuilding: "Capacity building",
    Mediaconsulting: "Media consulting",
    Riskcrisismanagement: "Risk & crisis management",
    EventManagement: "Event Management",
    Budgeting: "Budgeting , Sponsors",
    Communication: "Communication",
    Suppliers: "Suppliers, Partners, Contractors",
    Design: "Design, Planning, Management",
    Marketing: "Marketing, Coordinating",
    Location: "Location, Travel, Logistic",
    ManagedIT: "Managed IT Services",
    EndTechnology: "End-To-End Technology Management",
    Strategy: "Strategy, Planning, & Consulting",
    ITSupport: "IT Support & Helpdesk",

    whoWeAre: "Who we are?",
    weAreSmartAchievements:
      "We're a Smart-Achievement, a social media communications agency based in Jordan. We understand the importance of a brands social media presence.",
    weGetToKnowThemAndTheir:
      "We get to know them and their target audiences intimately, then we create, design, develop and communicate brands and their messages in an impactful & engaging way on their social media platforms.",
    whatsIncluded: "What's Included?",
    servicesFollowingPlatforms: "Serving following platforms",
    offerings: "Offerings",
    ourTeam: "Our Team",
    managementConsultations: "Management Consultations",
    mediaConsultating: "Media Consulting",
    publicRelations: "Public Relations",
    connectTheClient: "Connect the client with other parties",
    provideAllElectronic: "Provide all electronic services",
    moreServices: "More Services",
    smartAchievements: "Smart Achievements",
    weThinkOfSolutions: "We think of solutions for your business to stand out in the international market, in a safe, fast , automated way & with maximum efficency",
    contactUs: "Contact Us : ",
    allRightsReserved: "ALL RIGHTS RESERVED",

    socialMediaAccountSetupText: "Social account setup, developing business pages on different social media platforms",
    socialMediaStrategyText: "Social media strategy, positioning and targeting on social media",
    socialMediaPlanText: "Social media plan, social media calendar in accordance with strategy",
    contentCreationText: "Content creation, copy, image, gif, video, slideshow and canvas",
    postingsText: "Posting, tracking the best time to post through software. Interest targeting (based on different interest group). Demographic targeting (based on gender, age, relationship status, income, job title, education qualification, etc). Behavior targeting (based on travel, use of technology, events participation, etc). look-a-like audience targeting (based on the existing audience, finding the new one similar to before",
    socialmedialisteningText: "Social media listening, tracking the brand mention and responding to them",
    BusinesspageoptimizationText: "Social media listening, tracking the brand mention and responding to them Business page optimization, optimizing the page to get higher rank in relevant categories",
    KeyperformancemetricsText: "Key performance metrics reporting, visual report showing analytics to make easy strategic decision",
    IncreasenetworkpopularityText: "Increase network popularity, increasing fan base in targeted audience",
    ReputationmanagementText: "",
    motionGraphicsText: "Motion graphics (sometimes MO graph) are pieces of animation or digital footage which create the illusion of motion or rotation, and are usually combined with audio for use in multimedia projects",
    WritingprofessionalcontentText: "Write a Head-Turning Headline. The headline determines whether audiences will read the rest of your work. Create a Hook That Grabs Their Attention. Do Your Research. Focus on a Single Purpose. Write in a Unique Voice. Optimize Digital Content. Edit Your Work",
    GraphicvideophotomakerText: "",
    mediaConsultatingText: "",
    InfluencerIdentificationText: "",
    industryAndKeywordResearchText: "Industry and keyword research, to create relevant ads and specific targeting",
    creativeDevelopmentText: "Creative development, creative that is catchy and follow norms of advertisement on social media (SM)",
    campaignManagementText: "Campaign management, creating ads set and campaign",
    mediaBuyingText: "In the ever-evolving landscape of marketing, media buying plays a critical role in ensuring the success of advertising campaigns with an understanding of marketing goals and target audience preferences, media buyers are responsible for executing the actual purchase of advertisement space. Through strategic negotiations with various sites, networks, and channels, media buyers aim to achieve optimal ad placement to maximize return on investment (ROI)",
    RemarketingText: "Conversion rate optimization and retargeting (or remarketing) have become two of the most commonly used phrases by marketers these days, especially when it comes to ecommerce. Marketers are always on the lookout for ways to increase their conversion rate. And, why won’t they? After all, conversion rate is the best measure that can predict the success or failure for a business. Conversion rate is all about",
    DesigninglogoText: "a visual communication tool to help you get your message across to the audience. Logos are composed of text, color, shape, and illustrations. It serves as a self-explanatory image that people can use to connect with your brand",
    GEOtargetingText: "GEO targeting (based on city, region or country)",


    ConnecttheclientText: "Trust is paramount. First and foremost, all good relationships are based on trust. Managing the relationship is a long-term commitment. Clear communication leads to mutual success. Culture fit is critical. Good consultants make a big difference.  BRINGING IT ALL TOGETHER",
    PublicrelationsText: "experience. creativity. transparency. expectations. flexibility. personalities. professionalism",
    EntrepreneurshipText: "includes the creation or extraction of economic value. It is the act of being an entrepreneur, or the owner or manager of a business enterprise who, by risk and initiative, attempts to make profits. Entrepreneurs act as managers and oversee the launch and growth of an enterprise",
    QualityText: "we’ll define several common roles for a quality management consulting service. \n 1. Help You Achieve and Maintain Compliance \n 2. Help You Implement a QMS (quality management systems) \n 3. Train Your Team \n 4. Help You Work Through CAPA (corrective and preventative action system)",
    HumanresourcesText: "HR Consulting without the fluff. \n 1. Recruitment \n 2. Legal stuff \n 3. Rewards \n 4. Performance \n 5. Training \n 6. Have fun",
    MarketresearchText: `What are the steps to conduct market research for your management consulting projects? \n 1. Define the problem \n 2. Design the research \n 3. Conduct the research \n 4. Interpret the results \n 5. Communicate the findings \n 6. Review and refine \n 7. Here’s what else to consider`,
    FeasibilitystudyText: "The Feasibility Study – Key Factors \n 1. Business Alignment \n 2. Technology and System Assessment \n 3. Economic Viability \n 4. Operational Considerations \n 5. Legal Ramifications \n 6. Schedule and Resource Concerns \n 7. Market Dynamics \n 8. Company Cultural & Political Concerns",
    BusinessplanText: "What should a business plan include? \n 1. The executive summary \n 2. A description of the business \n 3. The market(s) the business will operate in \n 4. A SWOT (strengths, weaknesses, opportunities and threats) analysis. \n 5. Management team and personnel \n 6. The products or services offered \n 7. Marketing \n 8. A financial plan",
    ProcessandprocedureText: "Guidelines for procedure development \n 1. Start with the end in mind \n 2. Establish a format, create a template \n 3. Interview your subject matter experts \n 4. Layout the process \n 5. Test and revise \n 6. Approve then publish",
    StrategicplanText: "Smart Achievement’s strategic management consultants can work with you on: \n 1.Mission, vision and values creation \n 2. Strengths and capability assessment \n 3. Competitive positioning \n 4. Value proposition design \n 5. Long and short term strategy development \n 6. Action planning \n 7. KPI development \n 8. Implementation funding and governance.",
    CapacitybuildingText: "What factors should you consider when choosing capacity building tools for a project? Powered by AI and the LinkedIn community \n 1. Define your capacity building needs \n 2. Consider your target audience \n 3. Evaluate the quality and suitability of the tools \n 4. Balance the costs and benefits of the tools \n 5. Adapt and customize the tools as needed",
    RiskcrisismanagementText: "",

    BudgetingText: "",
    CommunicationText: "",
    SuppliersText: "",
    DesignText: "",
    MarketingText: "",
    LocationText: "These are the most effective ways of transforming an event space.  Create an event theme (or mix multiple together) Create focal points Use unique event furniture and props Utilise lighting and sound (AV) Consider event layout",

    EndTechnologyText: "SA can help you manage your IT, Cloud, & Communications environment from end-to-end. From support to procurement, deployment, security, and ongoing management, we can look after nearly all of your technology needs so you can focus on what’s important, your customers",
    StrategyText: "When planning for next phase for your business, SA can partner with you to develop tailored technology solutions that ensure your technology supports your growing business needs. SA is the trusted partner to a wide range of small businesses across a variety of industries. If you need a partner, not just a provider, then talk to us today, we would love to hear from you",
    ITSupportText: "Our outstanding local helpdesk team are ready to start providing your business with responsive and reliable IT support. From everyday IT support through to critical IT management for servers, firewalls, and security needs, we can do it all. Need someone onsite in a hurry? No problem, we're here to help",
  },
  ar: {
    ManagementConsultant: "الإستشارات الإدارية",
    digitalmedialmanagment: "	إدارة الوسائط الرقمية",
    Managmentconsultant: "الاستشارات الإدارية",
    EventManagement: "إدارة الحفلات و الفعاليات	",
    ManagedIT: "إدارة خدمات تكنولوجيا المعلومات	",
    services: "خدماتنا",
    aboutUs: "عنّا",
    getInTouch: "تواصل معنا",
    we: "نحن",
    create: "نبتكر",
    connect: "نربط",
    converse: "نطوّر",
    toHelpBrand: "لتسويق العلامات التجارية على وسائل التواصل الاجتماعي  ",
    weBoostBrand:
      "دعم العلامات التجارية من خلال تقديم الأفكار الإبداعيةو الحملات الدعائية و الاستراتيجيات الخلاقة",
    supportedPlatforms: "المنصات المدعومة",
    weTakeDigitalMedia:
      "نقوم بإدارة وسائل التواصل الاجتماعي بشكل يومي و بطريقة مدمجة",
    soYouCanFocus:
      "لتتمكن من التركيز على أهدافك المتمثلة بالنهوض بمشاريعك و تطويرها",
    ourServices: "خدماتنا",
    boostYourBrand: "دعم علامتك التجارية بكفاءة و احترافية",
    digitalMediaSolutions: "خدمات التواصل الاجتماعي",
    socialMediaMarketing: "التسويق على منصات التواصل الاجتماعي",
    weManageAllYourSocial: "ندير حسابات وسائل التواصل الإجتماعي بطريقة إبداعية",
    readMore: "اقرأ المزيد",
    essentialSocialMedia: "نقدم إستشارات إدارية بما يضمن رفع جودة الأعمال",
    socialMediaAdsManagement: "إدارة الإعلانات على مواقع التواصل الاجتماعي",
    optimizeAds:
      "ننظم وندير الحفلات والفعاليات بأسلوب إحترافي ضمن فريق عمل مؤهل",
    letsGetInTouch: "لنتواصل",
    sendUsYourRequest: "ارسل لنا طلبك و سيقوم فريقنا",
    weWillGetBackToYou: "بالرد عليك خلال ٢٤ ساعة",
    fullName: "الاسم الكامل",
    email: "البريد الالكتروني",
    companyName: "اسم الشركة",
    mobile: "رقم الهاتف",
    yourMessage: "رسالتك",
    optional: "اختياري",
    emailPlaceholder: "example@email.com",
    companyPlaceHolder: "اسم الشركة",
    mobilePlaceHolder: "96279XXXXXXX",
    letsHaveADiscussion: "تواصل معنا",
    send: "ارسال",
    theBestWayForUs:
      "أفضل وسيلة لإيصال رسالتنا هي عن طرق المشاريع التي نقدمها لعملائنا",
    wePrioritizeDynamic:
      "اولويتنا هي تقديم الخطط العملية و الاحترافية لعلامتك التجارية على مواقع التواصل",
    socialMediaPostDesign: "تصميم اعلانات مواقع التواصل الاجتماعي",
    targetedSocialMedia:
      " استهداف الفئات المطلوبة لزيادة المشاهدة لعلامتك التجارية",
    facebook: "فيسبوك",
    instagram: "انستغرام",
    youtube: "يوتيوب",
    linkedIn: "لينكد ان",
    twitter: "تويتر(اكس)",
    pinterest: "بينتريست",
    weBlendSocialMedia:
      "ادارة الاعلانات بطريقة مدمجة مع الاستشارات لتحقيق افضل الاستراتيجيات",
    developStategy: "تطوير الاستراتيجيات",
    identificationOfTargetAudience: "تحديد الفئات المطلوبة",
    auditsAndCompetitiveAnalysis: "التحليل و المراجعة للخطط و الخدمات",
    socialMediaBestPractices: "افضل الخدمات في مواقع التواصل",
    contestsAndPromtions: "تنظيم الحملات الاعلانية و الدعائية",
    influencerIdentification: "التواصل مع المؤثرين على مواقع التواصل",
    whoWeAre: "نبذة عنا",
    weAreSmartAchievements:
      "نحن شركة الانجاز الذكي، شركة متخصصة في التسويق الإلكتروني و إدارة مواقع التواصل الاجتماعي مقرها في العاصمة الاردنية عمّان. نحن ندرك اهمية الحضور على مواقع التواصل الإجتماعي للعلامات التجارية.",
    weGetToKnowThemAndTheir:
      "نقوم بفهم الرؤية للعلامات التجارية و معرفة الجمهور المستهدف لها بطريقة تتيح لنا تصميم تنسيق و تطوير هذه الرؤية بالطريقة المثلى لجذب هذا الجمهور و التواصل معه من خلال المنصات الاجتماعية.",
    whatsIncluded: "على ماذا تشتمل؟",
    servicesFollowingPlatforms: "المنصات المشمولة",
    offerings: "الخدمات المقدمة",
    ourTeam: "فريقنا",
    managementConsultations: "الاستشارات الإدارية",
    mediaConsultating: "ااستشارات الدعائية",
    publicRelations: "العلاقات العامة",
    connectTheClient: "بناء جسور ربط العلاقات بين مختلف الزبائن",
    provideAllElectronic: "تقديم كامل الخدمات الإلكترونية",
    moreServices: "خدمات اخرى",
    smartAchievements: "الإنجاز الذكي",
    weThinkOfSolutions: "نقدم خدمات و حلول إلكترونية مبتكرة بما يضمن السرعة والكفاءة في العمل",
    contactUs: "يمكنك الوصول من خلال : ",
    allRightsReserved: "جميع الحقوق محفوظة",

    socialMediaAccountSetup: "ضبط حسابات وسائل التواصل الإجتماعي",
    socialMediaStrategy: " بناء استراتيجيات وسائل التواصل الإجتماعي",
    socialMediaPlan: "التخطيط الفعال لوسائل التواصل الإجتماعي",
    contentCreation: "كتابة المحتوى بطريقة إبداعية",
    postings: "إدارة عمليات النشر و الإعلان",
    socialmedialistening: "متابعة أصداء العلامة التجارية على وسائل التواصل الإجتماعي  ",
    Businesspageoptimization: "تحسين محركات البحث ",
    Keyperformancemetrics: "قياس مؤشرات الآداء على مواقع التواصل الإجتماعي",
    Increasenetworkpopularity: "زيادة متابعين ورفع شهرة وسائل التواصل الإجتماعي ",
    Reputationmanagement: "إدارة السمعة الإلكترونية ",
    Motiongraphics: "خدمات الموشن جرافيك (الرسوم المتحركة)",
    Graphicvideophotomaker: "تصميم ومونتاج الفيديوهات بطريقة إبداعية",
    industryAndKeywordResearch: "التصوير الفوتوغرافي الإبداعي",
    Mediaconsulting: "تقديم استشارات في مجال الميديا و التسويق الإلكتروني ",
    InfluencerIdentification: "التسويق عبر المؤثرين",
    campaignManagement: "إدارة الحملات الإعلانية",
    Remarketing: "متابعة عائدات الإستثمار بهدف اجراء عمليات التحسين",
    Designinglogo: "تصميم الشعار الخاص للعلامة التجارية ",
    Creativedevelopment: "تصميم الهوية البصرية",
    GEOtargeting: "استهداف الإعلانات بحسب التوزيع الجغرافي للفئات المستهدفة",

    Publicrelations: "خدمات العلاقات العامة",
    Entrepreneurship: "ريادة الأعمال",
    Quality: "إدارة الجودة الشاملة",
    Humanresources: "الموارد البشرية",
    Marketresearch: "الأبحاث  والخطط السوقية ",
    Feasibilitystudy: "الخطط التسويقية",
    Businessplan: "دراسة الجدوى",
    Processandprocedure: "العمليات و الإجراءات",
    Strategicplan: "وضع الخطط الإستراتيجية",
    Capacitybuilding: "بناء القدرات",
    Riskcrisismanagement: "إدارة الأزمات و المخاطر",

    Budgeting: "وضع  الميزانية وتحديد الداعمين",
    Communication: " التواصل مع كافة أصحاب العلاقة",
    Suppliers: "تحديد الموردين و الشركاء و أصحاب العقود",
    Design: "خدمات التصوير التصميم ",
    Marketing: "التسويق و التنظيم ",
    Location: "الخدمات اللوجيستية  ",

    EndTechnology: "تبني إدارة العمليات التكنولوجية ",
    Strategy: "الإستراتيجيات ، التخطيط و العمليات الإستشارية",
    ITSupport: "تقديم الدعم التكنولوجي و التقني   ",

  },
};

const selectedLanguage = localStorage.getItem("language") || "en";

export const strings = languages[selectedLanguage];
