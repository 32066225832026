import { strings } from "../config/localization";

const ContactUS = () => {
    return (
        <div className="contact-us-section">
            <p>{strings.email} :</p>
            <p className="email-section">info@smartachievements.com</p>
        </div>
    );
};

export default ContactUS;
