import { useEffect, useState } from "react";
import OurServicesPage from "../components/services/OurServices";
import { SERVICES_DATA } from "../components/services/ServicesData";
import ServiceSection from "../components/services/ServicesSection";
import { useSearchParams } from "react-router-dom";

const Services = () => {
  const [searchParams] = useSearchParams();
  const [active, setActive] = useState(null);

  const [mainService, setMainService] = useState(null); // null || index
  const [subService, setSubService] = useState(null); // null || index

  const handleShowing = (main, sub) => {
    if (main === mainService && sub === subService) {
      setMainService(null);
      setSubService(null);
    } else {
      setMainService(main);
      setSubService(sub);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActive(searchParams.get("index"));
  }, []);

  useEffect(() => {
    if (active) {
      const service = document.getElementById(active);
      service.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });

      setTimeout(() => {
        service.style.transition = "all 1s";
        service.style.backgroundColor = "var(--Royal-Blue-50, #eef)";
        service.classList.add("pulse");
      }, 500);

      setTimeout(() => {
        service.style.backgroundColor = "#fff";
        service.classList.remove("pulse");
      }, 2500);
    }
  }, [active]);

  return (
    <div style={styles.contStyles}>
      <OurServicesPage />
      <div className="all-services-cont">
        {SERVICES_DATA.map((item, index) => (
          <ServiceSection
            handleShowing={handleShowing}
            subId={index + 1}
            key={index}
            item={item}
            mainService={mainService} // 1
            subService={subService} // 5
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  contStyles: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F6F7F9",
  },
};

export default Services;
