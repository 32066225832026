import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { handleScrolling } from "../utils";
import { strings } from "../config/localization";

const NavBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const [rotate, setRotate] = useState(false);
  const [language, setLanguage] = useState("en");

  const handleLanguage = () => {
    window.location.reload();
    setLanguage((language) => (language === "en" ? "ar" : "en"));
    localStorage.setItem("language", language);
  };

  useEffect(() => {
    const language = localStorage.getItem("language") || "en";
    setLanguage(language === "en" ? "ar" : "en");
    if (language) {
      document
        .getElementById("root")
        .setAttribute("dir", language === "en" ? "ltr" : "rtl");
    }
  }, []);

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => setRotate((r) => !r), 3000);

    return () => {
      resetTimeout();
    };
  }, [rotate]);

  return (
    <div className="nav-bar">
      <Link to="/" className="nav-bar-logo">
        <div style={logoDivStyle}>
          <img
            className={`ellipse-logo ${rotate ? "rotate" : null}`}
            src={require("../assets/Ellipse-logo.svg").default}
            alt="Ellipse"
          />
          <div>
            <h1 style={titleStyles}>{strings.smartAchievements}</h1>
          </div>
        </div>
      </Link>
      <div className="nav-box">
        <Link
          to="/services"
          className={
            pathname === "/services"
              ? "nav-box-selected nav-bar-pages"
              : "nav-bar-pages"
          }
        >
          {strings.services}
        </Link>
        <Link
          to="/about-us"
          className={
            pathname === "/about-us"
              ? "nav-box-selected nav-bar-pages"
              : "nav-bar-pages"
          }
        >
          {strings.aboutUs}
        </Link>
        <button onClick={handleScrolling} className="nav-btn get-in-touch-btn">
          {strings.getInTouch}
        </button>

        <button className="btn-lang" onClick={handleLanguage}>
          {language === "en" ? "English" : "العربية"}
        </button>
      </div>
    </div>
  );
};

export default NavBar;

const logoDivStyle = {
  display: "flex",
  gap: "4px",
};

const titleStyles = {
  contain: "inline-size",
};
