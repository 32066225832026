import { InputCell } from "./inputCell";
import { strings } from "../config/localization";
import { useState } from "react";
import { validateEmail, validateNumber } from "../utils";
import axios from "axios";


const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

const ContactForm = () => {
  const initialData = {
    name: "",
    number: "",
    email: "",
    companyName: "",
    message: "",
  }
  const [contactInfo, setContactInfo] = useState({ ...initialData })
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState("")
  const [error, setError] = useState("")
  const handleSendingMessage = async () => {
    try {
      setLoading(true)

      await axios.post(`https://abkutcw4ic4felkuckkzn7lh5u0cgglp.lambda-url.eu-central-1.on.aws`,
        {
          "email_address": contactInfo.email,
          "full_name": contactInfo.name,
          "company_name": contactInfo.companyName,
          "mobile_number": contactInfo.number,
          "message": contactInfo.message,
          "form_type": "contact_form"
        }, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      setContactInfo({ ...initialData })
      setLoading(false)
      setSuccess("Thank you for reaching out!")
      setTimeout(() => {
        setSuccess("")
      }, 5000)
    } catch (error) {
      setLoading(false)
      setError('Something went wrong , try again later please')
      setSuccess("")
    }
  }

  const handleOnChange = (code, value) => {
    setError("")
    setContactInfo({
      ...contactInfo,
      [code]: value
    })
  }

  const handleSubmitting = () => {
    if (!contactInfo.name?.length) {
      setError("Please enter your name")
    }
    else if (!validateEmail(contactInfo.email)) {
      setError("Incorrect email format")
    }
    else if (!validateNumber(contactInfo.number)) {
      setError("Incorrect contact number format 9627xxxxxxxx")
    } else if (!contactInfo.message?.length) {
      setError("Please enter your message")

    }
    else {
      setError("")
      handleSendingMessage()
    }
  }

  return (
    <div className="form-box">
      <div
        className="form"
      >
        <div className="personal-details">
          <InputCell
            onChange={(e) => handleOnChange('name', e.target.value)}
            name={strings.fullName}
            placeholder={strings.fullName}
            type={"text"}
            value={contactInfo.name}
            required={"required"}
          />
          <InputCell
            onChange={(e) => handleOnChange('email', e.target.value)}
            name={strings.email}
            placeholder={strings.emailPlaceholder}
            type={"text"}
            value={contactInfo.email}
            required={"required"}
          />
          <InputCell
            onChange={(e) => handleOnChange('companyName', e.target.value)}
            name={strings.companyName}
            placeholder={strings.companyPlaceHolder}
            type={"text"}
            value={contactInfo.companyName}
          />
          <InputCell
            onChange={(e) => handleOnChange('number', e.target.value)}
            name={strings.mobile}
            placeholder={strings.mobilePlaceHolder}
            type="number"
            required={"required"}
            value={contactInfo.number}
          />
        </div>
        <div className="message">
          <InputCell
            onChange={(e) => handleOnChange('message', e.target.value)}
            name={strings.yourMessage}
            required={"required"}
            placeholder={strings.letsHaveADiscussion}
            type={"text"}
            value={contactInfo.message}
          />
          {error?.length ? <p style={styles.errorStyles}>{error}</p> : null}
          {success?.length ? <p style={styles.successStyles}>{success}</p> : null}
        </div>
      </div>
      <div className="form-btn-box">
        <button className="form-btn get-in-touch-btn" onClick={handleSubmitting}>
          {loading ? <LoadingSpinner /> :
            strings.send}</button>
      </div>
    </div>
  );
};

const styles = {
  errorStyles: {
    color: "#ef4444"
  },
  successStyles: {
    color: "#A0E635"
  }
}

export default ContactForm;
