export const handleScrolling = () => {
    const section = document.querySelector('#contact-us');
    section.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
}

export const validateEmail = (email) => {
    const emailRegex = new RegExp("[a-z0-9]+@[a-z]+\.[a-z]{2,3}")
    return emailRegex.test(email)
};

export const validateNumber = (number) => {
    const numberRegex = new RegExp("^[0-9]{3}7[789][0-9]{7}$")
    return numberRegex.test(number)

}