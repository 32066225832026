import PlatformBox from "./platformBox";
import { useState } from "react";
import Slideshow from "./slideshow";
import { handleScrolling } from "../utils";
import { strings } from "../config/localization";

const Herosection = () => {
  const [active, setActive] = useState(false);

  const handleActive = () => {
    !active ? setActive(true) : setActive(false);
  };

  return (
    <div className="hero-section">
      <div className="hero-box">
        <div className="hero-title-box">
          <div className="hero-slide-show">
            <h1 className="we-img">{strings.we}</h1>
            <Slideshow />
          </div>
          <h2>{strings.toHelpBrand}</h2>
        </div>
        <p className="hero-text">{strings.weBoostBrand}</p>

        <button onClick={handleScrolling} className="hero-btn get-in-touch-btn">
          {strings.getInTouch}
        </button>
        <h3>{strings.supportedPlatforms}</h3>
        <div className="platform-box">
          <PlatformBox />
        </div>
      </div>
      <div
        className="hero-img-box"
        onMouseEnter={handleActive}
        onMouseLeave={handleActive}
      >
        <img
          className="ellipse"
          src={require(`../assets/Ellipse.png`)}
          alt="ell"
        />
        <img
          className="hero"
          src={require(`../assets/man-pic.png`)}
          alt="hero"
        />
        <img
          className="pixel-hero"
          src={require(`../assets/pixel-hero.png`)}
          alt="pixel"
        />
        <img
          className={`hero-bubble hero-bubble-${active ? "active" : null}`}
          src={require(`../assets/hero-bubble.png`)}
          alt="bubble"
        />
        <img
          className={`hero-heart hero-heart-${active ? "active" : null}`}
          src={require(`../assets/hero-heart.png`)}
          alt="heart"
        />
      </div>
      <div className="hero-static-box">
        <img
          className="hero-static"
          src={require("../assets/Hero-static.png")}
          alt="Hero-static"
        />
      </div>
    </div>
  );
};

export default Herosection;
