import { useState } from "react";
import { Link } from "react-router-dom";
import { handleScrolling } from "../utils";
import { strings } from "../config/localization";

const OurServices = () => {
  const [activeSpeaker, setActiveSpeaker] = useState(false);
  const handleActiveSpeaker = () => {
    !activeSpeaker ? setActiveSpeaker(true) : setActiveSpeaker(false);
  };
  const [activeBell, setActiveBell] = useState(false);
  const handleActiveBell = () => {
    !activeBell ? setActiveBell(true) : setActiveBell(false);
  };

  const [activeStarship, setActiveStarship] = useState(false);
  const handleActiveStarship = () => {
    !activeStarship ? setActiveStarship(true) : setActiveStarship(false);
  };
  return (
    <div className="service-section">
      <div className="service-text">
        <h1>{strings.ourServices}</h1>
        <div>
          <p>{strings.boostYourBrand} </p>
          <p>{strings.digitalMediaSolutions}</p>
        </div>
        <button
          onClick={handleScrolling}
          className="service-btn get-in-touch-btn"
        >
          {strings.getInTouch}
        </button>
      </div>
      <div
        className="service-box-1"
        onMouseEnter={handleActiveSpeaker}
        onMouseLeave={handleActiveSpeaker}
      >
        <div className="service-box-1-text">
          <div className="service-box-inner-text">
            <h1>{strings.digitalmedialmanagment}</h1>
            <h2>{strings.weManageAllYourSocial}</h2>
          </div>
          <Link to={"/services?index=1"} style={styles.readMoreStyles}>
            <div className="read-more-box">
              <p>{strings.readMore}</p>
              <img
                alt=">"
                src={require(`../assets/arrow-right.png`)}
                style={styles.imageStyles}
              />
            </div>
          </Link>
        </div>
        <div className="service-box-1-image-box">
          <img
            className={`speaker speaker-${activeSpeaker ? "active" : null}`}
            src={require(`../assets/speaker.png`)}
            alt="speaker"
          />
          <img
            className={`bubble bubble-${activeSpeaker ? "active" : null}`}
            src={require(`../assets/blue-bubble.png`)}
            alt="bubble"
          />
          <img
            className={`heart heart-${activeSpeaker ? "active" : null}`}
            src={require(`../assets/heart.png`)}
            alt="heart"
          />
        </div>
        <div className="service-box-1-image-static">
          <img
            className="speaker-static"
            src={require("../assets/speaker-static.png")}
            alt="speaker-static"
          />
        </div>
      </div>
      <div className="rest-service-box">
        <div
          className="service-box-2"
          onMouseEnter={handleActiveBell}
          onMouseLeave={handleActiveBell}
        >
          <div className="service-box-title-div">
            <div>
              <h1>{strings.ManagementConsultant}</h1>
              <h2>{strings.essentialSocialMedia}</h2>
            </div>
            <Link to={"/services?index=3"} style={styles.readMoreStyles}>
              <div className="read-more-box">
                <p>{strings.readMore}</p>
                <img
                  src={require(`../assets/arrow-right.png`)}
                  alt=">"
                  style={styles.imageStyles}
                />
              </div>
            </Link>
          </div>
          <div className="service-box-2-image-box">
            <img
              className={`bell bell-${activeBell ? "active" : null}`}
              src={require(`../assets/bell.png`)}
              alt="bell"
              style={styles.bellStyles}
            />
          </div>
          <div className="service-box-2-image-static">
            <img
              className="bell-static"
              src={require("../assets/bell-static.png")}
              alt="bell-static"
            />
          </div>
        </div>
        <div
          className="service-box-3"
          onMouseEnter={handleActiveStarship}
          onMouseLeave={handleActiveStarship}
        >
          <div className="service-box-title-div">
            <h1>{strings.EventManagement}</h1>
            <h2>{strings.optimizeAds}</h2>
            <Link to={"/services?index=4"} style={styles.readMoreStyles}>
              <div className="read-more-box">
                <p>{strings.readMore}</p>
                <img
                  src={require(`../assets/arrow-right.png`)}
                  alt=">"
                  style={styles.imageStyles}
                />
              </div>
            </Link>
          </div>

          <div className="service-box-3-image-box">
            <img
              className={`starship starship-${activeStarship ? "active" : null
                }`}
              src={require(`../assets/spaceship.png`)}
              alt="spaceship"
              style={styles.starShipStyles}
            />
          </div>
          <div className="service-box-3-image-static">
            <img
              className="starship-static"
              src={require("../assets/starship-static.png")}
              alt="starship-static"
            />
          </div>
        </div>

        <div className="service-box-4">
          <div className="service-box-title-div">
            <h1>{strings.ManagedIT}</h1>
            <h2>{strings.weThinkOfSolutions}</h2>
            <Link to={"/services?index=5"} style={styles.readMoreStyles}>
              <div className="read-more-box">
                <p>{strings.readMore}</p>
                <img
                  src={require(`../assets/arrow-right.png`)}
                  alt=">"
                  style={styles.imageStyles}
                />
              </div>
            </Link>
          </div>
          <div className="target-img-box">
            <img className="target" src={require("../assets/target.png")} style={styles.starShipStyles} alt="target" />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  imageStyles: {
    width: 20,
    transform:
      localStorage.getItem("language") === "ar"
        ? "rotate(180deg)"
        : "rotate(0deg)",
  },
  starShipStyles: {
    left: localStorage.getItem("language") === "ar" ? 0 : "unset",
    right: localStorage.getItem("language") === "en" ? 0 : "unset",
    bottom: (localStorage.getItem("language") || "en") === "en" ? "0%" : "-20%",
  },
  bellStyles: {
    left: localStorage.getItem("language") === "ar" ? 30 : "unset",
    right: localStorage.getItem("language") === "en" ? 30 : "unset",
  },
  readMoreStyles: {
    right: localStorage.getItem("language") === "ar" ? 25 : "unset",
    left: localStorage.getItem("language") === "en" ? 25 : "unset",
  }
};

export default OurServices;
