import { strings } from "../../config/localization";

const OurServices = () => {
    return (
        <div className="our-services-title-section">
            <h1 className="our-services-title-styles">{strings.ourServices}</h1>
            <p className="our-services-description-styles">
                {strings.theBestWayForUs}
            </p>
        </div>
    );
}


export default OurServices;
