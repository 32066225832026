import { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const ServiceSection = ({
  item,
  subId,
  mainService,
  subService,
  handleShowing,
}) => {
  const [showArrow, setShowArrow] = useState(true);

  const getArrow = (innerItem, index) => {
    if (innerItem?.text?.length) {

      return subId === mainService && index + 1 === subService ? (
        <img
          src={
            require("../../assets/chevron-up-svgrepo-com.svg")
              .default
          }
          alt="test"
          style={{ width: 24, height: 24 }}
        />
      ) : (
        <img
          src={
            require("../../assets/chevron-down-svgrepo-com.svg")
              .default
          }
          alt="test"
          style={{ width: 24, height: 24 }}
        />
      )
    }
    return null
  }

  return (
    <div className="service-section-cont" id={subId}>
      <h1 className="service-title">{item.title}</h1>
      <p className="service-description">{item.description}</p>
      <p className="services-section-inner-title">{item.innerTitle}</p>

      {item.data?.map((innerItem, index) => {
        if (innerItem?.description?.length) {

          return <Accordion key={index}>
            <AccordionItem
              dangerouslySetExpanded={
                subId === mainService && index + 1 === subService
              }
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div
                    className="single-service"
                    onClick={() => {
                      handleShowing(subId, index + 1);
                      setShowArrow((s) => !s);
                    }}
                  >
                    <div className="section-service-cont">
                      <img src={innerItem.image} className="check-mark-styles" />
                      <p className="service-image-description">
                        {innerItem.description}
                      </p>
                    </div>
                    {getArrow(innerItem, index)}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              {innerItem.text?.length ? <AccordionItemPanel>
                <p style={{ lineHeight: 1.7, "white-space-collapse": "preserve-breaks" }}>{innerItem.text}</p>
              </AccordionItemPanel> : null}
            </AccordionItem>
          </Accordion>
        }
        return null
      })}
    </div>
  );
};

export default ServiceSection;
