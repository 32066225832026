import { useState, useEffect, useRef } from "react";
import { strings } from "../config/localization";

const IMAGES = [strings.create, strings.connect, strings.converse];

const Slideshow = () => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === IMAGES.length - 1 ? 0 : prevIndex + 1
        ),
      2000
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(0,${-index * 33}%, 0)` }}
      >
        {IMAGES.map((img, index) => (
          <div key={index} className="slide">
            <h1>{img}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
